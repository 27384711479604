<template>
  <el-form
    ref="editorForm"
    class="editor-form"
    :model="form"
    label-width="100px"
    label-position="right">
    <el-form-item
      prop="name"
      label="配置名称：">
      <el-select
        @change="handelChangeCifig"
        v-model="form.name"
        placeholder="请选择"
        style="width: 83%">
        <el-option
          v-for="item in lists"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      <el-button
        type="primary"
        style="margin-left: 18px"
        @click="handleDelConfig"
        v-if="form.extra_json.length !== 0"
        >解绑</el-button
      >
    </el-form-item>
    <el-form-item
      label="配置选项："
      v-if="form.extra_json.length !== 0">
      <div
        v-for="(items, index) in form.extra_json"
        :key="index">
        <el-row :gutter="24">
          <el-col
            class="item-name"
            :span="7"
            :style="index === 0 ? 'margin-left:-10px;' : ''">
            <el-form-item> {{ items.name }}： </el-form-item>
          </el-col>
          <el-col
            class="input-item"
            :span="16">
            <el-form-item>
              <el-input
                readonly
                type="textarea"
                placeholder="请输入内容"
                v-model="items.value">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'Config',
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: '',
    },
    lists: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    handelChangeCifig(v) {
      this.$emit('change', v);
    },
    handleDelConfig() {
      this.$emit('delConfig');
    },
  },
};
</script>

<style lang="scss">
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #5b03e4;
  border-color: #5b03e4;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #5b03e4;
}
.editor-form .item-name {
  text-align: right;
}
</style>
