<template>
  <div
    class="application"
    v-loading="pageLoading">
    <div class="flex">
      <div
        class="add"
        @click="handelAdd">
        新增应用
      </div>
    </div>
    <bc-table
      :table-header="tableHeader"
      :table-data="tableData">
      <template v-slot:operate>
        <el-table-column
          :label="'操作'"
          align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="handelBindShow(scope.row)"
              type="primary">
              配置
            </el-button>
            <el-button
              size="mini"
              @click="handelEdit(scope.row)"
              type="primary">
              编辑
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDisable(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </template>
    </bc-table>
    <bc-pagination
      v-show="total > 0"
      :total="total"
      :page.sync="pageData.page"
      :limit.sync="pageData.limit"
      @pagination="loadData" />

    <bc-dialog
      class="base-dialog bind-dialog"
      width="850px"
      :visible="isShowBindDialog"
      @confirm="handleBindConfirm"
      @cancel="handleBindCancel"
      title="配置管理">
      <template v-slot:dialogContent>
        <el-tabs
          v-model="activeFrom"
          type="card"
          @tab-click="handleTabClick">
          <el-tab-pane
            v-for="(items, index) in formLists"
            :key="index"
            :label="`${items.name}(${items.num})`"
            :name="items.id.toString()">
            <config
              :form="configForm"
              :lists="bindOptions"
              @change="changeBindName"
              @delConfig="delConfigBind"
              class="config-form"
              v-if="items.num !== 0"></config>
            <div
              v-else
              class="nodata">
              暂无配置，去<span
                @click="handelAddConfig"
                class="router"
                >添加配置</span
              >吧！
            </div>
          </el-tab-pane>
        </el-tabs>
      </template>
    </bc-dialog>
    <bc-dialog
      width="550px"
      :visible="isShowDialog"
      :title="type === 'add' ? '新增应用' : '编辑应用'"
      class="base-dialog"
      @confirm="handleConfirm"
      @cancel="handleCancle">
      <template v-slot:dialogContent>
        <el-form
          ref="editorForm"
          class="editor-form"
          :model="form"
          :rules="rules"
          label-width="100px"
          label-position="right">
          <h5>基本信息</h5>
          <el-form-item
            prop="name"
            label="名称">
            <el-input
              v-model.trim="form.name"
              placeholder="请输入名称">
            </el-input>
          </el-form-item>
          <el-form-item
            prop="package_name"
            label="包名">
            <el-input
              v-model.trim="form.package_name"
              placeholder="请输入包名">
            </el-input>
          </el-form-item>
          <h5>登录参数配置</h5>
          <el-form-item
            prop="generate_type"
            label="id生成倾向">
            <el-radio-group
              v-model="form.generate_type"
              :disabled="type !== 'add'"
              @change="handelChange">
              <el-radio label="1">系统</el-radio>
              <el-radio label="2">传值</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="form.generate_type === '1'"
            prop="generate_rule"
            label="id生成规则">
            <el-radio-group
              :disabled="type !== 'add'"
              v-model="form.generate_rule"
              @change="handelChange">
              <el-radio label="1">系统固定</el-radio>
              <el-radio label="2">系统随机</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="账号模式"
            class="merge_uid">
            <div class="el-icon-warning-outline"></div>
            <div class="warning">
              合并帐号：同一设备上登录的第三方（新）、游客合并成一个帐号<br />
              区分游客：同一设备上登录的第三方（新），合并成一个帐号，游客单独一个帐号<br />
              独立帐号：游客、第三方(新)，分别生成独立的帐号<br />
            </div>
            <el-select
              clearable
              v-model="form.is_merge_uid"
              :disabled="type !== 'add'"
              placeholder="请选择"
              style="width: 100%">
              <el-option
                v-for="item in mergeOptions"
                :key="item.value"
                :label="item.name"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <h5>支付参数配置</h5>
          <el-form-item
            prop="pay_notify_url"
            label="支付回调地址">
            <el-input
              v-model.trim="form.pay_notify_url"
              placeholder="请输入您的服务器的Webhook地址">
            </el-input>
          </el-form-item>
          <el-form-item
            prop="refund_notify_url"
            label="退款回调地址">
            <el-input
              v-model.trim="form.refund_notify_url"
              placeholder="请输入退款回调地址">
            </el-input>
          </el-form-item>
          <h5>归因数据参数</h5>
          <el-form-item
            prop="adjust_mode"
            label="adjust模式">
            <el-radio-group
              v-model="form.adjust_mode"
              @change="handelChange">
              <el-radio label="0">沙盒</el-radio>
              <el-radio label="1">生产</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            prop="adjust_status"
            label="adjust状态">
            <el-radio-group
              v-model="form.adjust_status"
              @change="handelChange">
              <el-radio label="0">禁用</el-radio>
              <el-radio label="1">启用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </template>
    </bc-dialog>
  </div>
</template>

<script>
import {
  getAppList,
  appAdd,
  appEdit,
  getThirdsList,
  appThirdList,
  getFromList,
  bindAppThird,
  getThirdInfo,
  appDel,
  delAppThird,
} from '@/api/application';
import Config from '@/components/Config';

export default {
  name: 'application',
  components: {
    Config,
  },
  data() {
    return {
      tableData: [],
      total: 0,
      pageData: {
        page: 1,
        limit: 20,
      },
      tableHeader: [
        {
          prop: 'id',
          label: '编号',
          width: 100,
        },
        {
          prop: 'name',
          label: '应用名称',
          width: 200,
        },
        {
          prop: 'bind_froms',
          label: '渠道',
          width: 200,
        },
        {
          prop: '',
          label: '对接功能',
          width: 200,
          render: () => {
            return <span>登录，注册</span>;
          },
        },
        {
          prop: 'key',
          label: '应用key',
        },
        {
          prop: 'secret',
          label: '应用密钥',
          render: (h, row, index) => {
            return (
              <div>
                <span>
                  {row.secretShow ? row.secret : this.visibleKey(row.secret)}
                </span>
                <i
                  class={['fa', row.secretShow ? 'fa-eye-slash' : 'fa-eye']}
                  onClick={() => this.handelSecrect(row, index)}></i>
              </div>
            );
          },
        },
      ],
      isShowDialog: false,
      pageLoading: false,
      form: {
        name: '',
        pay_notify_url: '',
        is_merge_uid: true,
        package_name: '',
        refund_notify_url: '',
        adjust_mode: '0',
        adjust_status: '0',
        generate_type: '1',
        generate_rule: '1',
      },
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        package_name: [
          { required: true, message: '请输入包名', trigger: 'blur' },
        ],
        // pay_notify_url: [
        //   { required: true, message: '请输入服务器的Webhook地址', trigger: 'blur' },
        // ],
      },
      type: 'add',
      isShowBindDialog: false,
      bindOptions: [],
      bindValue: '',
      appId: 0,
      appThirdLists: [],
      mergeOptions: [
        {
          name: '合并帐号(按设备)',
          value: 1,
        },
        {
          name: '区分游客(按设备)',
          value: 0,
        },
        {
          name: '独立帐号(按渠道)',
          value: 2,
        },
      ],
      formLists: [],
      activeFrom: '1',
      configForm: {
        from: '1',
        extra_json: [],
      },
      showNodata: false,
      thirdbindId: '',
    };
  },
  methods: {
    handleConfirm() {
      this.$refs['editorForm'].validate((valid) => {
        if (valid) {
          this.isShowDialog = false;
          this.type === 'add' ? this.addData() : this.editData();
        } else {
          return false;
        }
      });
    },
    handleCancle() {
      this.$refs['editorForm'].resetFields();
      this.isShowDialog = false;
    },
    handelSecrect(item, index) {
      this.$set(this.tableData[index], 'secretShow', !item.secretShow);
    },
    handelAdd() {
      this.isShowDialog = true;
      this.type = 'add';
      this.form.name = '';
      this.form.package_name = '';
      this.form.pay_notify_url = '';
      this.form.refund_notify_url = '';
      this.form.is_merge_uid = 1;
      this.form.adjust_mode = '0';
      this.form.adjust_status = '0';
      this.form.generate_type = '1';
      this.form.generate_rule = '1';
    },
    handelChange() {
      this.$forceUpdate();
    },
    handelEdit(item) {
      this.type = 'edit';
      this.isShowDialog = true;
      this.form = JSON.parse(JSON.stringify(item));
      this.form.adjust_mode = item.adjust_config.mode;
      this.form.adjust_status = item.adjust_config.status;
      this.form.generate_type = item.generate_type.toString();
      this.form.generate_rule = item.generate_rule.toString();
    },
    addData() {
      appAdd(this.form).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message);
          this.loadData();
        }
      });
    },
    editData() {
      appEdit(this.form).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message);
          this.loadData();
        }
      });
    },
    loadData() {
      this.pageLoading = true;
      getAppList(this.pageData).then((res) => {
        res.data.list.forEach((i) => {
          i.secretShow = false;
        });
        this.tableData = res.data.list;
        this.total = res.data.pages.total;
        this.pageLoading = false;
      });
    },
    getOption(from) {
      getThirdsList({
        limit: 10000,
        page: 1,
        from: from,
      }).then((res) => {
        this.bindOptions = res.data.list;
      });
    },
    toBind() {
      this.$router.push('/console/third');
    },
    showMessage() {
      const h = this.$createElement;
      return this.$message({
        type: 'warning',
        message: h('p', null, [
          h('span', null, '暂无可绑定配置! '),
          h(
            'span',
            {
              style: 'color:#5b03e4;cursor: pointer',
              on: {
                click: this.toBind,
              },
            },
            '去新增'
          ),
        ]),
      });
    },
    async handelBindShow(item) {
      this.appId = item.id;
      this.bindValue = '';
      this.appThirdLists = [];
      this.getFromLists();
      await this.handleTabClick({ name: '1' });
      this.isShowBindDialog = true;
    },
    handleBindConfirm() {
      if (this.configForm.extra_json.length !== 0) {
        this.handelAddBind();
      } else {
        this.$message.warning('去配置吧!');
        this.isShowBindDialog = false;
      }
    },
    handleBindCancel() {
      this.isShowBindDialog = false;
    },
    handelAddBind() {
      let params = {
        app_id: this.appId,
        third_id: this.bindValue,
        from: this.activeFrom,
      };
      bindAppThird(params).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message);
          this.isShowBindDialog = false;
          this.loadData(this.appId);
        }
      });
    },
    getFromLists() {
      getFromList({
        page: 1,
        limit: 1000,
        status: 1,
      }).then((res) => {
        this.formLists = res.data;
      });
    },
    async handleTabClick(v) {
      this.activeFrom = v.name;
      this.configForm.from = v.name;
      this.getOption(this.activeFrom);
      const { data } = await appThirdList({
        app_id: this.appId,
      });
      this.appThirdLists = data.list;
      let fromConfig = [];
      if (this.appThirdLists.length !== 0) {
        fromConfig = this.appThirdLists.find(
          (item) => item.from === Number(v.name)
        );
        if (fromConfig) {
          this.thirdbindId = fromConfig.id;
          if (v.name === '1') {
            fromConfig.third_info.extra_json = {
              client_id: fromConfig.third_info.client_id,
              service_client_id: fromConfig.third_info.service_client_id,
            };
          }
          this.configForm = JSON.parse(JSON.stringify(fromConfig.third_info));

          this.configForm.extra_json = Object.entries(
            fromConfig.third_info.extra_json
          ).map(([name, value]) => ({
            name,
            value,
          }));
          this.bindValue = this.configForm.id;
        } else {
          this.configForm = {
            extra_json: [],
          };
        }
      } else {
        this.configForm = {
          extra_json: [],
        };
      }
    },

    handelAddConfig() {
      this.$router.push('/console/third');
      this.isShowBindDialog = false;
    },
    changeBindName(v) {
      this.bindValue = v;
      getThirdInfo({
        id: v,
      }).then((res) => {
        this.configForm = res.data;
        if (this.activeFrom === '1') {
          this.configForm.extra_json = [
            { name: 'client_id', value: this.configForm.client_id },
            {
              name: 'service_client_id',
              value: this.configForm.service_client_id,
            },
          ];
        }
      });
    },
    delConfigBind() {
      delAppThird({
        id: this.thirdbindId,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success('解绑成功！');
          this.handleTabClick({ name: this.activeFrom });
        }
      });
    },
    visibleKey(val) {
      let len = val.length;
      return val.substring(0, 8) + '*****' + val.substring(len - 8, len);
    },
    handleDisable(row) {
      this.$confirm('此操作将永久删除该应用, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          appDel({ id: row.id }).then((res) => {
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '删除成功!',
              });
              this.loadData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.application {
  margin: 0 auto;
  font-size: 14px;
  padding: 30px;
  .flex {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    .add {
      padding: 5px 10px;
      border-radius: 5px;
      background-color: #5b03e4;
      color: #fff;
      text-align: center;
      width: 100px;
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss">
.application .fa {
  color: #5b03e4;
  cursor: pointer;
  margin-left: 10px;
}
.base-dialog {
  h5 {
    font-size: 15px;
    margin-bottom: 10px;
  }
  h5::before {
    width: 3px;
    height: 20px;
    background-color: #5b03e4;
    display: inline-block;
  }
}
.bind-dialog {
  .config-form {
    width: 99%;
    margin: 0 auto;
  }
  .nodata {
    text-align: center;
    margin: 100px 0;
  }
  .router {
    color: #5b03e4;
    cursor: pointer;
  }
}
.bind-btn {
  padding: 0 20px;
  background-color: #5b03e4;
  color: #fff;
  font-size: 12px;
  border-radius: 8px;
  height: 25px;
  line-height: 25px;
  display: inline-block;
}
.base-dialog {
  .bind-btn {
    height: 35px;
    line-height: 35px;
    margin-left: 10px;
  }

  .warning {
    transition: display 0.4s ease-in-out;
    background: #faecdb;
    color: #e6a23c;
    padding: 10px;
    border-radius: 10px;
    font-size: 12px;
    line-height: 20px;
    position: absolute;
    left: -90px;
    bottom: -80px;
    display: none;
    z-index: 999;
  }
  .marks {
    font-size: 12px;
    color: #f56c6c;
  }
  .el-icon-warning-outline {
    color: #e6a23c;
    font-size: 20px;
    position: absolute;
    left: -90px;
    top: 10px;
    cursor: pointer;
  }
  .el-icon-warning-outline:hover + .warning {
    display: block;
  }
  .merge_uid {
    position: relative;
  }
}
</style>
